<template>
  <div class="container-fluid">
    <breadcrumb>
     <img  src="static/img/organisation.svg" class="breadcrumb-image" />
      <breadcrumb-item>
        <router-link to="/organisations">Organisations</router-link>
      </breadcrumb-item>
      <breadcrumb-item v-if="add" active> Add Organisation </breadcrumb-item>
      <breadcrumb-item v-if="add == false" active>
        Edit Organisation
      </breadcrumb-item>
    </breadcrumb>
    <form autocomplete="off">
      <div class="row">
        <div class="col-md-6">
          <card v-if="loaded">
            <div>
              <fg-input
                name="name"
                label="Name"
                v-validate="modelValidations.name"
                :error="getError('name')"
                v-model="model.name"
              ></fg-input>
              <fg-input
                name="address line 1"
                label="Address Line 1"
                v-validate="modelValidations.address1"
                :error="getError('address line 1')"
                v-model="model.address1"
              ></fg-input>
              <fg-input
                name="address2"
                label="Address Line 2"
                v-model="model.address2"
              ></fg-input>
              <fg-input
                name="city"
                label="City"
                v-validate="modelValidations.city"
                :error="getError('city')"
                v-model="model.city"
              ></fg-input>
              <fg-input
                name="postcode/zip code"
                label="Postcode/Zip Code"
                v-validate="modelValidations.postcode"
                :error="getError('postcode/zip code')"
                v-model="model.postcode"
              ></fg-input>
              <fg-input label="Country" :error="getError('country')">
                <el-select
                  name="country"
                  v-validate="modelValidations.country"
                  class="select-default"
                  style="width: 100%"
                  v-on:input="ddlCountryChange"
                  v-model="model.countryId"
                  placeholder="Select Country"
                >
                  <el-option
                    class="select-default"
                    v-for="item in this.countries"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </fg-input>
              <fg-input
                name="telephone"
                label="Telephone"
                v-validate="modelValidations.phone"
                :error="getError('telephone')"
                v-model="model.phone"
              ></fg-input>
              <fg-input label="Industry" :error="getError('industry')">
                <el-select
                  name="industry"
                  v-validate="modelValidations.industry"
                  class="select-default"
                  style="width: 100%"
                  v-on:input="ddlIndustryChange"
                  v-model="model.industryId"
                  placeholder="Select Industry"
                >
                  <el-option
                    class="select-default"
                    v-for="item in this.industries"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </fg-input>
            </div>
          </card>
        </div>

        <div class="col-md-6">
          <card v-if="loaded">
            <div>
              <fg-input
                label="Level of Exposure"
                :error="getError('exposure level')"
              >
                <el-select
                  name="exposure level"
                  v-validate="modelValidations.exposureLevel"
                  class="select-default"
                  style="width: 100%"
                  v-on:input="ddlExposureLevelChange"
                  v-model="model.exposureLevel"
                  placeholder="Select Exposure Level"
                >
                  <el-option
                    class="select-default"
                    v-for="item in model.exposureLevels"
                    :key="item"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </fg-input>

              <fg-input
                label="Organisation Type"
                :error="getError('organisation type')"
              >
                <el-select
                  name="organisation type"
                  v-validate="modelValidations.organisationType"
                  class="select-default"
                  style="width: 100%"
                  v-on:input="ddlOrganisationTypeChange"
                  v-model="model.organisationType"
                  placeholder="Select Organisation Type"
                >
                  <el-option
                    class="select-default"
                    v-for="item in model.organisationTypes"
                    :key="item"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </fg-input>

              <fg-input label="Business Activities">
                <textarea
                  name="businessActivities"
                  class="form-control"
                  v-model="model.businessActivities"
                  style="height: 80px"
                ></textarea>
              </fg-input>
              <fg-input label="Additional Information">
                <textarea
                  name="additionalInformation"
                  v-model="model.additionalInformation"
                  class="form-control"
                  style="height: 80px"
                ></textarea>
              </fg-input>
              <checkbox v-model="checkboxValue">Active</checkbox>
            </div>
            <div class="card-footer text-right">
              <button
                type="button"
                @click="close"
                class="btn btn-fill btn-danger btn-wd"
              >
                <span class="btn-label">
                  <i class="fa fa-times"></i>
                </span>
                Cancel
              </button>
              <button
                type="submit"
                @click.prevent="validate"
                @click="save"
                class="btn btn-fill btn-info btn-wd"
              >
                <span class="btn-label">
                  <i class="fa fa-save"></i>
                </span>
                Save
              </button>
            </div>
          </card>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { Checkbox } from "src/components/index";
import { Select, Option } from "element-ui";
import { Breadcrumb, BreadcrumbItem } from "src/components/index";

export default {
  components: {
    Checkbox,
    [Option.name]: Option,
    [Select.name]: Select,
    Breadcrumb,
    BreadcrumbItem,
  },
  data() {
    return {
      checkboxValue: true,
      model: {
        id: 0,
        name: "",
        address1: "",
        address2: "",
        postcode: "",
        country: "",
        city: "",
        industry: "",
        phone: "",
        additionalInformation: "",
        businessActivities: "",
        isActive: true,
        countryId: null,
        industryId: null,
        exposureLevel: "",
        organisationType: "",
        organisationTypes: ["Main", "Business Unit", "Vendor"],
        exposureLevels: ["Very Low", "Low", "Medium", "High", "Very High"],
      },
      filterable: true,
      countries: [],
      industries: [],

      modelValidations: {
        name: {
          required: true,
        },
        address1: {
          required: true,
        },
        postcode: {
          required: true,
        },
        city: {
          required: true,
        },
        phone: {
          required: true,
        },
        country: {
          required: true,
        },
        industry: {
          required: true,
        },
        exposureLevel: {
          required: true,
        },
        organisationType: {
          required: true,
        },
      },
      loaded: false,
      add: false,
    };
  },
  created() {
    let self = this;
    self.get(self.$route.params.id);
    self.getAllActiveCountries();
    self.getAllActiveIndustries();
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$validator.validateAll().then((isValid) => {
        this.$emit("on-submit", this.organisation, isValid);
      });
    },
    getAllActiveCountries() {
      let self = this;
      self.$store.state.services.countryService
        .getAll({
          params: {
            ActiveCountries: true,
          },
        })
        .then((r) => {
          self.countries = r.data;
        })
        .catch((err) => console.log(err));
    },
    getAllActiveIndustries() {
      let self = this;
      self.$store.state.services.industryService
        .getAll({
          params: {
            ActiveIndustries: true,
          },
        })
        .then((r) => {
          self.industries = r.data;
        })
        .catch((err) => console.log(err));
    },
    get(id) {
      let self = this;
      if (id == undefined) {
        self.add = true;
        self.loaded = true;
        return;
      }
      self.$store.state.services.organisationService
        .get(id)
        .then((r) => {
          self.model.id = r.data.id;
          self.model.name = r.data.name;
          self.model.address1 = r.data.address1;
          self.model.address2 = r.data.address2;
          self.model.postcode = r.data.postcode;
          self.model.country = r.data.country;
          self.model.city = r.data.city;
          self.model.industry = r.data.industry;
          self.model.countryId = r.data.countryId;
          self.model.industryId = r.data.industryId;
          self.model.businessActivities = r.data.businessActivities;
          self.model.phone = r.data.phone;
          self.model.additionalInformation = r.data.additionalInformation;
          var myValue = r.data.isActive == "True";
          self.model.isActive = myValue;
          self.model.exposureLevel = r.data.exposureLevel;
          self.model.organisationType = r.data.organisationType;
          self.checkboxValue = myValue;
          self.loaded = true;
        })
        .catch((err) => console.log(err));
    },
    save() {
      let self = this;
      self.$validator.validateAll().then((valid) => {
        if (valid) {
          if (self.model.id > 0) {
            self.model.isActive = self.checkboxValue.toString();
            self.model.exposureLevel = self.model.exposureLevel.replace(
              /\s/g,
              ""
            );
            self.model.organisationType = self.model.organisationType.replace(
              /\s/g,
              ""
            );
            self.$store.state.services.organisationService
              .update(self.model)
              .then((r) => {
                self.notifyVue("top", "right", "success");
                self.close();
              })
              .catch((err) => console.log(err));
          } else {
            self.model.isActive = self.checkboxValue.toString();
            self.model.exposureLevel = self.model.exposureLevel.replace(
              /\s/g,
              ""
            );
            self.model.organisationType = self.model.organisationType.replace(
              /\s/g,
              ""
            );
            self.$store.state.services.organisationService
              .add(self.model)
              .then((r) => {
                self.notifyVue("top", "right", "success");
                self.close();
              })
              .catch((err) => console.log(err));
          }
        }
      });
    },
    close() {
      let self = this;
      self.$router.push("/organisations");
    },
    ddlCountryChange(value) {
      let self = this;
      self.model.countryId = value;
    },
    ddlIndustryChange(value) {
      let self = this;
      self.model.industryId = value;
    },
    ddlExposureLevelChange(value) {
      let self = this;
      self.model.exposureLevel = value;
    },
    ddlOrganisationTypeChange(value) {
      let self = this;
      self.model.organisationType = value;
    },
    notifyVue(verticalAlign, horizontalAlign, messageType) {
      this.$notify({
        component: {
          template: `<span></br>Organisation saved</br>&nbsp;</span>`,
        },
        icon: "fa fa-check",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: messageType,
      });
    },
  },
  /**watch: {
      country (newValue) {
        model.countryId = this.countries[newValue].id;
        window.alert(model.countryId)
      }
    }**/
};
</script>
